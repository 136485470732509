.verifikasi-user {
  .alert-data .ant-alert-content {
    flex-grow: 0;
    flex-basis: auto;
  }

  .box-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 880px;
  }

  .flow-user {
    max-width: 472px;
  }

  .container-step {
    width: 600px;
  }

  .finish-result {
    padding-top: 0;
  }

  .finish-change {
    padding: 0;
  }

  .ant-btn > .anticon + span {
    margin-left: 4px !important;
  }

  .btn-setup {
    width: 348px;
  }

  .image-container {
    display: flex;
    flex-direction: column;
  }

  .fixed-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
  }

  .btn-support {
    display: flex;
    padding: 11px 16px !important;
    border-radius: 24px;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--polar-green-6, #18c753);
    background: var(--polar-green-7, #0d9f3f);
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);
    color: #ffffff;
  }

  .setup-now,
  .setup-soon {
    .ant-card {
      max-width: 240px;
    }
  }
  .email-input-setup {
    max-width: 240px;
    margin-left: 90px;
  }
  .check-icon {
    font-size: 70px;
    padding: 5px;
  }
  &.status {
    max-width: 472px;
    .btn-navigate {
      max-width: 348px;
      width: 100%;
    }
  }
  .card-setup:hover {
    border-radius: 2px;
    box-shadow: 0 0 0 8px rgba(24, 199, 83, 0.1);
  }
  .card-setup-active {
    border: 1px solid #0d9f3f;
    border-radius: 2px;
    box-shadow: 0 0 0 8px rgba(24, 199, 83, 0.1);
  }
  .card-setup .ant-card-body {
    padding: 24px 24px 0 24px;
  }
  .card-setup .header-5 {
    font-weight: 400;
    line-height: 24px;
  }
  .svg-card {
    max-width: 180px;
    max-height: 140px;
  }
  .setup-form {
    max-width: 720px;
  }
  .setup-form .header-2 {
    font-weight: 500;
    line-height: 40px;
    margin-bottom: 4px;
  }
}

.ant-popover-title {
  font-size: 16px;
  padding: 0 16px !important;
}

.content-support {
  color: var(--character-secondary-45, rgba(0, 0, 0, 0.45));
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.thumbnail-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 0 8px 0 !important;
}

.red-icon-overlay {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  max-width: 250px;
  max-height: 140px;
}

.red-icon-overlay svg {
  width: 36px;
  height: 36px;
  color: #f5222d;
}

@green-1: #E5FAEC;@green-2: #BBF2CE;@green-3: #8FEDAF;@green-4: #63DF8D;@green-5: #3DD370;@green-6: #18C753;@green-7: #0D9F3F;@green-8: #057B2D;@green-9: #004F1B;@green-10: #00270D;@primary-color: #0D9F3F;@success-color: #0D9F3F;