header.header-setup {
  padding: 32px 40px 20px 40px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  background: #ffffff;
}
.dropdown-header {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

header.header-flow {
  padding: 40px 0px 0px 40px;
}

@green-1: #E5FAEC;@green-2: #BBF2CE;@green-3: #8FEDAF;@green-4: #63DF8D;@green-5: #3DD370;@green-6: #18C753;@green-7: #0D9F3F;@green-8: #057B2D;@green-9: #004F1B;@green-10: #00270D;@primary-color: #0D9F3F;@success-color: #0D9F3F;