:where(.css-dev-only-do-not-override-26avgc)
  .right-required
  .ant-form-item-label
  > label::after {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  line-height: 1;
  content: "*";
}

.ant-form-vertical .right-required .ant-form-item-label > label::after {
  visibility: inherit;
}

.validate-modal.ant-modal .ant-modal-content {
  padding: 32px 32px 20px 32px;
}

.ant-btn-primary.btn-modal {
  background: #0d9f3f;
}

.ant-btn-primary.btn-modal:hover {
  border: 1px solid #0d9f3f;
  background: #29ab52 !important;
}

.warning-icon .anticon svg {
  width: 19.25px;
  height: 19.25px;
  padding-top: 2px;
}

.delete-modal.ant-modal .ant-modal-content {
  padding: 32px 32px 24px 32px;
}

.delete-modal.ant-modal .ant-modal-footer {
  margin-top: 36px;
}

.quota-claim.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #8c8c8c;
  font-size: 19.25px;
  padding-top: 2px;
}

.quota-claim.ant-modal-confirm .ant-modal-confirm-btns {
  margin-top: 24px;
}

.error-claim.ant-modal-confirm .ant-modal-confirm-btns {
  margin-top: 24px;
}

.order-list {
  padding-inline-start: 16px;
  margin-bottom: 0;
  margin-top: 8px;
}

.bulk-download.anticon svg {
  width: 12px;
  height: 12px;
  padding-left: 4px;
}

@green-1: #E5FAEC;@green-2: #BBF2CE;@green-3: #8FEDAF;@green-4: #63DF8D;@green-5: #3DD370;@green-6: #18C753;@green-7: #0D9F3F;@green-8: #057B2D;@green-9: #004F1B;@green-10: #00270D;@primary-color: #0D9F3F;@success-color: #0D9F3F;