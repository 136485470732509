.profile-pic.ant-card .ant-card-body {
  padding: 24px;
}

.profileQuota.ant-card .ant-card-body {
  padding: 24px 24px 32px 24px;
}

.tag-contact.ant-tag.ant-tag-blue {
  color: #1890ff;
}

.desc-company .ant-descriptions-row {
  box-shadow: inset 0px -1px 0px #f0f0f0;
  & > td {
    padding-top: 8px;
    padding-bottom: 12px;
  }
}

.title-precentage {
  font-size: 24px !important;
  line-height: 32px !important;
}

.profileQuota .ant-progress-line {
  margin-inline-end: 0;
  margin-bottom: 0;
}

.form-password .ant-form-item {
  margin-bottom: 16px;
}

.form-password .ant-col-24.ant-form-item-label {
  padding: 0;
}

@green-1: #E5FAEC;@green-2: #BBF2CE;@green-3: #8FEDAF;@green-4: #63DF8D;@green-5: #3DD370;@green-6: #18C753;@green-7: #0D9F3F;@green-8: #057B2D;@green-9: #004F1B;@green-10: #00270D;@primary-color: #0D9F3F;@success-color: #0D9F3F;