.flex {
  display: flex;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-col {
  flex-direction: column;
}
.flex-reverse {
  flex-direction: row-reverse;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.items-center {
  align-items: center;
}
.items-baseline {
  align-items: baseline;
}
.items-stretch {
  align-items: stretch;
}
.gap-1 {
  gap: 4px;
}
.gap-2 {
  gap: 8px;
}
.gap-3 {
  gap: 12px;
}
.gap-4 {
  gap: 16px;
}
.gap-5 {
  gap: 20px;
}
.gap-6 {
  gap: 24px;
}
.gap-x-1 {
  column-gap: 4px;
}
.gap-x-2 {
  column-gap: 8px;
}
.gap-x-3 {
  column-gap: 12px;
}
.gap-x-4 {
  column-gap: 16px;
}
.gap-x-5 {
  column-gap: 20px;
}
.gap-x-6 {
  column-gap: 24px;
}
.gap-x-15 {
  column-gap: 60px;
}
.gap-y-1 {
  row-gap: 4px;
}
.gap-y-2 {
  row-gap: 8px;
}
.gap-y-3 {
  row-gap: 12px;
}
.gap-y-4 {
  row-gap: 16px;
}
.gap-y-5 {
  row-gap: 20px;
}
.gap-y-6 {
  row-gap: 24px;
}
.gap-y-7 {
  row-gap: 28px;
}
.gap-y-10 {
  row-gap: 40px;
}
